import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';
import moment from 'moment';
import { ApiService } from 'ets-fe-ng-sdk';
import { SalesAppService } from '../services/sales-app.service';
import { Subject } from 'rxjs';
import { StorageService } from '@Services/storage.service';
import { lastValueFrom } from 'rxjs';
import { IWebUser } from '@Authentication/web-user/web-user-authentication.model';
import { UtilityService } from '@Services/utility.service';

@Injectable({
  providedIn: 'root',
})
export class HomeService {
  baseURL = environment.apiBaseUrl + '/rest/';
  primaryCode: string = environment.webUser.primaryCode;
  teamCategory: string = environment.webUser.teamCat;
  reloadDashboard = new Subject<boolean>();
  hideBackBtn = false;
  wideView: boolean;
  constructor(
    public apiService: ApiService,
    private storageS: StorageService,
    private uS: UtilityService,
    private sappS: SalesAppService,
  ) {}

  resetDashboard() {
    this.primaryCode = this.sappS.webUser.primaryCode;
    this.teamCategory = this.sappS.webUser.teamCat;
    this.reloadDashboard.next(true);
    location.reload();
  }

  async triggerRefetch() {
    const p = [
      lastValueFrom(this.apiService.put(this.baseURL + 'sales/dashboard')),
      lastValueFrom(this.apiService.put(this.baseURL + 'v2/sales/agent/production/summary')),
      lastValueFrom(this.apiService.put(this.baseURL + 'v2/sales/agent/lead/performance')),
    ];
    await Promise.allSettled(p);
    return;
  }

  getAgentNoByClientNo(clientNo: string) {
    return this.apiService.get(this.baseURL + `agent/no/${clientNo}`);
  }

  getAgentName(agentNo: string) {
    this.apiService
      .getText(this.baseURL + `agent/name/${agentNo}`)
      .subscribe((res) => (this.sappS.webUser.memberName = res));
  }

  getUnconvertedQuotes(query?: any, teamCategory = this.teamCategory, primaryCode = this.primaryCode) {
    const query2 = {
      ...query,
      primaryAgent: primaryCode,
      primaryRelMgr: primaryCode,
      quoteStatus: 'A',
    };
    if (teamCategory === 'A') delete query2.primaryRelMgr;
    if (teamCategory === 'R') delete query2.primaryAgent;

    return this.apiService.get(this.baseURL + `policy/search`, query2);
  }

  getUnactivatedPolicies(teamCategory = this.teamCategory, primaryCode = this.primaryCode) {
    const query = {
      primaryAgent: primaryCode,
      primaryRelMgr: primaryCode,
      activated: false,
    };
    if (teamCategory === 'A') delete query.primaryRelMgr;
    if (teamCategory === 'R') delete query.primaryAgent;

    return this.apiService.get<any[]>(this.baseURL + `policy/search`, query);
  }

  getDueForRenewalPolicies(query?: any, teamCategory = this.teamCategory, primaryCode = this.primaryCode) {
    const query2 = {
      ...query,
      primaryAgent: primaryCode,
      primaryRelMgr: primaryCode,
      renewalDue: true,
      renewed: false,
    };
    if (teamCategory === 'A') delete query2.primaryRelMgr;
    if (teamCategory === 'R') delete query2.primaryAgent;

    return this.apiService.get(this.baseURL + `policy/search`, query2);
  }

  getActivatedPolicies(teamCategory = this.teamCategory, primaryCode = this.primaryCode) {
    console.log(teamCategory);
    const query = {
      primaryAgent: primaryCode,
      primaryRelMgr: primaryCode,
      activated: true,
    };
    if (teamCategory === 'A') delete query.primaryRelMgr;
    if (teamCategory === 'R') delete query.primaryAgent;

    return this.apiService.get<any[]>(this.baseURL + `policy/search`, query);
  }

  totalSuspense(query?: any, teamCategory = this.teamCategory, primaryCode = this.primaryCode) {
    const query2 = {
      ...query,
      agentNo: primaryCode,
      RM: primaryCode,
    };
    if (teamCategory === 'A') delete query2.RM;
    if (teamCategory === 'R') delete query2.agentNo;

    return this.apiService.get<any[]>(this.baseURL + `policy/suspense`, query2);
  }

  premiumDue(query?: any, teamCategory = this.teamCategory, primaryCode = this.primaryCode) {
    const query2 = {
      ...query,
      agentNo: primaryCode,
      RM: primaryCode,
    };
    if (teamCategory === 'A') delete query2.RM;
    if (teamCategory === 'R') delete query2.agentNo;

    return this.apiService.get<any[]>(this.baseURL + `policy/premium`, query2);
  }

  getLeadActivities(assignedTo = this.primaryCode) {
    return this.apiService.get(this.baseURL + `crm/client/assigned/lead/${assignedTo}`);
  }

  getActualYTD(agentNo = this.primaryCode) {
    return this.apiService.get(this.baseURL + `policy/compute/agent/production/${agentNo}`);
  }

  getCompanyBudget(companyCode: string) {
    return this.apiService.get(this.baseURL + `company/budget/${companyCode}`);
  }

  //-------------- Commissions Card Data Ends -----------------------------------//

  getPendingCommission(query?: any, agentNo = this.primaryCode) {
    return this.apiService.get(this.baseURL + `commission/posting/search`, {
      ...query,
      agentNo,
      processed: false,
    });
  }

  getEarnedYearToDate(query?: any, agentNo = this.primaryCode) {
    const now = moment();
    const startYear = this.uS.toJavaDatetimeString(now.startOf('year').format());
    return this.apiService.get(this.baseURL + `commission/statement/details/search`, {
      ...query,
      agentNo,
      createdFrom: startYear,
    });
  }

  getBalance(agentNo = this.primaryCode) {
    return this.apiService.get(this.baseURL + `commission/agent/balance/${agentNo}`);
  }

  getClawbackYTD(query?: any, agentNo = this.primaryCode) {
    const now = moment();
    const startYear = this.uS.toJavaDatetimeString(now.startOf('year').format());
    return this.apiService.get(this.baseURL + `commission/posting/search`, {
      ...query,
      agentNo: agentNo,
      createdOn: startYear,
    });
  }

  getStatements(query?: any, agentNo = this.primaryCode) {
    return this.apiService.get(this.baseURL + `commtype/statement/search`, {
      agentNo,
      ...query,
    });
  }

  //-------------- Commissions Card Data Start -----------------------------------//

  totalClaim(query?: any, teamCategory = this.teamCategory, primaryCode = this.primaryCode) {
    const query2 = {
      ...query,
      agentNo: primaryCode,
      RM: primaryCode,
    };
    if (teamCategory === 'A') delete query2.RM;
    if (teamCategory === 'R') delete query2.agentNo;
    return this.apiService.get(this.baseURL + `claim/search`, query2);
  }

  gettotalClaimYTD(query?: any, teamCategory = this.teamCategory, primaryCode = this.primaryCode) {
    const now = moment();
    const startYear = this.uS.toJavaDatetimeString(now.startOf('year').format());

    const query2 = {
      ...query,
      agentNo: primaryCode,
      RM: primaryCode,
      createdDateFrom: startYear,
    };
    if (teamCategory === 'A') delete query2.RM;
    if (teamCategory === 'R') delete query2.agentNo;
    return this.apiService.get(this.baseURL + `claim/search`, query2);
  }

  getPendingClaim(query?: any, teamCategory = this.teamCategory, primaryCode = this.primaryCode) {
    const query2 = {
      ...query,
      agentNo: primaryCode,
      RM: primaryCode,
      readyForPayout: false,
    };
    if (teamCategory === 'A') delete query2.RM;
    if (teamCategory === 'R') delete query2.agentNo;
    return this.apiService.get(this.baseURL + `claim/search`, query2);
  }

  searchLossRatio(query?: any, teamCategory = this.teamCategory, primaryCode = this.primaryCode) {
    const query2 = {
      ...query,
      agentNo: primaryCode,
      RM: primaryCode,
    };
    if (teamCategory === 'A') delete query2.RM;
    if (teamCategory === 'R') delete query2.agentNo;
    return this.apiService.get(this.baseURL + `claim/loss/ratio/search`, query2);
  }

  totalClients(agentNo = this.primaryCode) {
    return this.apiService.get(this.baseURL + `sales/client/list/${agentNo}`, {
      source: agentNo,
    });
  }

  pendingLeads(query?: any, agentNo = this.primaryCode) {
    return this.apiService.get(this.baseURL + `client/lead/search`, {
      ...query,
      assignedTo: agentNo,
    });
  }

  pendingPayouts(query?: any, teamCategory = this.teamCategory, primaryCode = this.primaryCode) {
    const query2 = {
      ...query,
      agentNo: primaryCode,
      RM: primaryCode,
      processed: false,
    };
    if (teamCategory === 'A') delete query2.RM;
    if (teamCategory === 'R') delete query2.agentNo;
    return this.apiService.get(this.baseURL + `payout/search`, query2);
  }

  convertedPolicies(query?: any, teamCategory = this.teamCategory, primaryCode = this.primaryCode) {
    const query2 = {
      ...query,
      primaryAgent: primaryCode,
      primaryRelMgr: primaryCode,
      quoteStatus: 'P',
    };
    if (teamCategory === 'A') delete query2.primaryRelMgr;
    if (teamCategory === 'R') delete query2.primaryAgent;

    return this.apiService.get<any[]>(this.baseURL + `policy/search`, query2);
  }

  activatedPolicies(query?: any, teamCategory = this.teamCategory, primaryCode = this.primaryCode) {
    const query2 = {
      ...query,
      primaryAgent: primaryCode,
      primaryRelMgr: primaryCode,
      activated: true,
    };
    console.log(query);
    if (teamCategory === 'A') delete query2.primaryRelMgr;
    if (teamCategory === 'R') delete query2.primaryAgent;

    return this.apiService.get<any[]>(this.baseURL + `policy/search`, query2);
  }

  unActivatedPolicies(query?: any, teamCategory = this.teamCategory, primaryCode = this.primaryCode) {
    const query2 = {
      ...query,
      primaryAgent: primaryCode,
      primaryRelMgr: primaryCode,
      activated: false,
      convertToPolicy: true,
    };
    if (teamCategory === 'A') delete query2.primaryRelMgr;
    if (teamCategory === 'R') delete query2.primaryAgent;

    return this.apiService.get<any[]>(this.baseURL + `policy/search`, query2);
  }

  eventForToday(query?: any, teamCategory = this.teamCategory, primaryCode = this.primaryCode) {
    let url = '';
    const now = moment();

    const query2 = {
      ...query,
      agentNo: primaryCode,
      userName: primaryCode,
      dateFrom: this.uS.toJavaDatetimeString(now.startOf('day').format()),
      dateTo: this.uS.toJavaDatetimeString(now.endOf('day').format()),
    };
    if (teamCategory === 'A') url = 'agent/list/calender/search';
    if (teamCategory === 'R') url = 'users/calender/search';
    return this.apiService.get(this.baseURL + url, query2);
  }

  eventForWeek(query?: any, teamCategory = this.teamCategory, primaryCode = this.primaryCode) {
    let url = '';
    const now = moment();

    const query2 = {
      ...query,
      agentNo: primaryCode,
      userName: primaryCode,
      dateFrom: this.uS.toJavaDatetimeString(now.startOf('week').toString()),
      dateTo: this.uS.toJavaDatetimeString(now.endOf('week').toString()),
    };
    if (teamCategory === 'A') url = 'agent/list/calender/search';
    if (teamCategory === 'R') url = 'users/calender/search';
    return this.apiService.get(this.baseURL + url, query2);
  }

  eventForMonth(query?: any, teamCategory = this.teamCategory, primaryCode = this.primaryCode) {
    let url = '';
    const now = moment();

    const query2 = {
      ...query,
      agentNo: primaryCode,
      userName: primaryCode,
      dateFrom: this.uS.toJavaDatetimeString(now.startOf('month').toString()),
      dateTo: this.uS.toJavaDatetimeString(now.endOf('month').toString()),
    };
    if (teamCategory === 'A') url = 'agent/list/calender/search';
    if (teamCategory === 'R') url = 'users/calender/search';
    return this.apiService.get(this.baseURL + url, query2);
  }

  getTotalEarned(agentNo = this.primaryCode) {
    return this.apiService.get(this.baseURL + `commission/agent/earned/${agentNo}`);
  }

  getProductionSummary(agentNo = this.primaryCode, memberCode: string) {
    return this.apiService
      .get<ProductionSummary[]>(this.baseURL + `agent/production/summary/${agentNo}`)
      .pipe(map((res) => res.filter((x) => x.memberCode === memberCode)));
  }

  getDashboardSummary(agentNo = this.primaryCode, memberCode: string) {
    return this.apiService
      .get<DashboardSummary[]>(this.baseURL + `agent/dashboard/${agentNo}`)
      .pipe(map((res) => res.filter((x) => x.memberCode === memberCode)));
  }

  getLeadSummary(agentNo = this.primaryCode, memberCode: string) {
    return this.apiService
      .get<LeadSummary[]>(this.baseURL + `agent/lead/summary/${agentNo}`)
      .pipe(map((res) => res.filter((x) => x.memberCode === memberCode)));
  }

  getWFSummary(username: string) {
    return this.apiService.get<any>(this.baseURL + `workflows/summary/${username}`);
  }

  /**
   * Call Endpoints that fetches commission statements
   * @param query
   * @returns
   */
  searchCommStatements(query?: any) {
    return this.apiService
      .get<any>(this.baseURL + `commission/statement/details/search`, query)
      .pipe(map((res) => res.content));
  }

  formatMonth = (m: number) => {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    return months[m];
  };
}

export interface ProductionSummary {
  actualYtd: number;
  actualYtdAvg: number;
  actualYtdTop: number;
  budgetFullYr: number;
  budgetYtd: number;
  memberCode: string;
  perfPrevYr: number;
  perfPrevYrAvg: number;
  perfPrevYrTop: number;
}

export interface DashboardSummary {
  activeEventCurrMo: string;
  activeEventCurrWk: string;
  activeEventTotal: string;
  clawbackYTD: string;
  commBalance: string;
  dueForRenewalPol: string;
  earnedYTD: string;
  inActiveClients: string;
  lossRatio: string;
  memberCode: string;
  noClient: string;
  pendingClaim: string;
  pendingComm: string;
  pendingLeads: string;
  premiumDue: string;
  totalClaims: string;
  totalClaimsYTD: string;
  totalSuspense: string;
  unconvertedQUO: string;
  pendingPayout: string;
  unActivatedPolicies: string;
  activatedPolicies: string;
  statements: string;
}

export interface LeadSummary {
  currentMoAvg: number;
  currentMoSelf: number;
  currentMoTop: number;
  currentWkAvg: number;
  currentWkSelf: number;
  currentWkTop: number;
  currentYrAvg: number;
  currentYrSelf: number;
  currentYrTop: number;
  memberCode: string;
}
