import { AsyncPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  LoaderComponent,
  AutocompleteComponent,
  BtnComponent,
  InputBasicComponent,
  configForms,
} from 'ets-fe-ng-sdk';
import { ModalHeaderComponent } from '@Shared/components/modal-header/modal-header.component';
import {
  ChartType, NgChartDirective
} from '@Shared/directives/ng-chart/ng-chart.directive';
import { SalesService } from '../sales.service';
import { TeamsService } from 'projects/evolutics-client-ui/src/app/CRM/crm-pages/Setup/codes/teams/teams.service';
import moment from 'moment';
import { UtilityService } from '@Services/utility.service';
import { lastValueFrom } from 'rxjs';
import { environment } from '@environments/environment';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslatePipe } from '@Shared/pipes/translate.pipe';

@Component({
    selector: 'app-sales-analytics',
    imports: [
        ModalHeaderComponent,
        InputBasicComponent,
        AutocompleteComponent,
        BtnComponent,
        NgChartDirective,
        LoaderComponent,
        TranslatePipe,
        AsyncPipe,
    ],
    templateUrl: './analytics.component.html',
    styleUrl: './analytics.component.scss'
})
export class SalesAnalyticsComponent implements OnInit {
  loading: boolean;
  chart = {
    type: 'bar' as ChartType,
    data: {
      labels: [
        'Conversions',
        'No of Calls',
        'No of Emails',
        'No of Visits',
        'Opportunties Assigned',
        'Prospects Assigned',
      ],
      datasets: [
        {
          data: [0, 0, 0, 0, 0, 0],
          backgroundColor: ['purple', '#f48225', 'yellow', 'green'],
          hoverBackgroundColor: ['purple', '#f48225', 'yellow', 'green'],
        },
      ],
    },
    options: <any>{
      responsive: true,
      plugins: {},
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              padding: 5,
              fontSize: 8,
            },
          },
        ],
        xAxes: [
          {
            barPercentage: 0.8,
            //categoryPercentage: 0.6,
            gridLines: {
              color: 'rgba(0,0,0,0)',
            },
            ticks: {
              fontSize: 12,
              padding: 10,
            },
          },
          {
            id: 'x-axis-1',
            type: 'category',
            display: false,
            categoryPercentage: 0.5,
            barPercentage: 1,
            gridLines: {
              offsetGridLines: true,
            },
          },
        ],
      },
      onClick: (e) => {},
    },
    plugins: <any>{},
  };
  chart2 = {
    type: 'bar' as ChartType,
    data: {
      datasets: [
        {
          data: [0, 0, 0, 0, 0, 0],
          backgroundColor: ['purple', '#f48225', 'yellow', 'green'],
          hoverBackgroundColor: ['purple', '#f48225', 'yellow', 'green'],
        },
      ],
    },
  };
  showChart = false;
  form = new FormGroup({
    teamCode: configForms.default(),
  });
  teamCodes: any[];
  teamData: any;
  memberData: any;
  constructor(
    private teamS: TeamsService,
    private salesS: SalesService,
    private uS: UtilityService,
    public dialogRef: MatDialogRef<SalesAnalyticsComponent>,
  ) {}

  async ngOnInit(): Promise<void> {
    this.loading = true;
    try {
      const data = await lastValueFrom(this.teamS.getMemberByMemberCode(environment.webUser.memberCode));
      const now = moment();
      const dateFrom = now.startOf('year').format('YYYY-MM-DD hh:mm:ss');
      const dateTo = moment(Date.now()).format('YYYY-MM-DD hh:mm:ss');
      const res1 = await lastValueFrom(
        this.salesS.getSalesActivityAnalyticByTeamCode({
          teamCode: data.teamData.code,
          dateFrom,
          dateTo,
        }),
      );
      this.chart.data.datasets[0].data = [
        res1.conversionsAssignedTeam,
        res1.noCallsTeam,
        res1.noEmailsTeam,
        res1.noVisitsTeam,
        res1.opportuntiesAssignedTeam,
        res1.prospectsAssignedTeam,
      ];
      this.teamData = {
        opportunityConversionRatioTeam: res1.opportunityConversionRatioTeam,
        prospectConversionRatioTeam: res1.prospectConversionRatioTeam,
      };
      const res2 = await lastValueFrom(
        this.salesS.getSalesActivityAnalyticByTeamCode({
          assignedTo: environment.webUser.memberCode,
          dateFrom,
          dateTo,
        }),
      );
      this.chart.data.datasets[0].data = [
        res2.conversionsAssignedTeam,
        res2.noCallsTeam,
        res2.noEmailsTeam,
        res2.noVisitsTeam,
        res2.opportuntiesAssignedTeam,
        res2.prospectsAssignedTeam,
      ];
      this.memberData = {
        opportunityConversionRatioTeam: res2.opportunityConversionRatioTeam,
        prospectConversionRatioTeam: res2.prospectConversionRatioTeam,
      };
      this.showChart = true;
    } catch (error) {
      this.uS.info(error, 0);
    }
    this.loading = false;
  }

  async show() {}
}
