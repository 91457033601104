import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IChartViewer } from '../chart-viewer/chart-viewer.interface';
import { NgStyle } from '@angular/common';
import { NgChartDirective } from '../../../../../evolutics-shared-lib/src/lib/Shared/directives/ng-chart/ng-chart.directive';

@Component({
    selector: 'app-bare-chart-viewer',
    templateUrl: './bare-chart-viewer.component.html',
    styleUrls: ['./bare-chart-viewer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NgChartDirective, NgStyle]
})
export class BareChartViewerComponent implements OnInit {
  chart: IChartViewer;
  constructor(
    private matDialog: MatDialogRef<BareChartViewerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.chart = this.data.chart;
    this.chart.options.scales.xAxes[0].ticks.fontSize = 15;
  }
}
