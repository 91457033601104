import { Component, Inject, OnInit, AfterViewInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { HomeService } from 'projects/evolutics-sales-ui/src/app/home/home.service';
import { UtilityService } from '@Services/utility.service';
import { TableCol } from '@Shared/models/index.model';
import {
  ITableSearchFunc,
  TablePlainComponent,
  TableHttpsComponent,
  LoaderComponent,
  ModalComponent,
  BtnComponent,
} from 'ets-fe-ng-sdk';
import { TranslatePipe } from '../../../../../evolutics-shared-lib/src/lib/Shared/pipes/translate.pipe';
import { ModalComponent as ModalComponent_1 } from '../../../../../evolutics-shared-lib/src/lib/Shared/components/modal/modal.component';
import { NgIf, AsyncPipe } from '@angular/common';
import { ModalHeaderComponent } from '../../../../../evolutics-shared-lib/src/lib/Shared/components/modal-header/modal-header.component';
import { RenewalAnalyticsComponent } from '../../home/renewal-analytics/renewal-analytics.component';

@Component({
    selector: 'app-table-viewer',
    templateUrl: './table-viewer.component.html',
    styleUrls: ['./table-viewer.component.scss'],
    encapsulation: ViewEncapsulation.None,
    imports: [
        ModalHeaderComponent,
        LoaderComponent,
        NgIf,
        TablePlainComponent,
        TableHttpsComponent,
        ModalComponent_1,
        AsyncPipe,
        TranslatePipe,
        BtnComponent
    ]
})
export class TableViewerComponent implements OnInit, AfterViewInit {
  @ViewChild('innerTableModal') innerTableModal: ModalComponent;
  loading: boolean;
  title: string;
  func: string;
  headings: TableCol[] = [];
  rows: any[];
  columns: TableCol[];
  allTableColumns: TableCol[] = [];
  innerLoading: boolean;
  innerTable = {
    heading: <string>'',
    data: <any[]>[],
    columns: <TableCol[]>[],
  };
  constructor(
    public dialogRef: MatDialogRef<TableViewerComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      slug: string;
      headings: TableCol[];
      func: string;
      paginate: boolean;
    },
    private uS: UtilityService,
    private homeS: HomeService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.title = this.data.title;
    const i = this.data.headings.findIndex((h: TableCol) => h.f === 'statementNo');
    if (i >= 0) {
      this.data.headings[i].action = (row: any) => this.openStatements(row);
    }
    this.headings = this.data.headings;
    this.columns = this.data.headings;
    this.func = this.data.func;
    this.columns.forEach((c) => {
      if (c.type === 'number') c.formatter = (val) => this.uS.moneyParser(val);
      if (c.type === 'date') c.formatter = (val) => this.uS.dateFormat(val);
    });
    if (!this.data.paginate) this.rows = await (this.homeS[this.func]() as Observable<any[]>).toPromise();
    this.loading = false;
  }

  observableFunc = (query: any) => this.homeS[this.func](query) as Observable<ITableSearchFunc<any>>;

  ngAfterViewInit(): void {}

  close() {
    this.dialogRef.close();
  }

  async openStatements(row: any) {
    console.log(row);
    this.innerLoading = true;
    this.innerTableModal.open();
    this.innerTable = {
      heading: `Showing Result for Statement No - ${row.statementNo}`,
      data: [],
      columns: [
        {
          f: 'receiptNo',
          t: 'Receipt No',
        },
        { f: 'coverCode', t: 'Cover Code' },
        { f: 'policyNo', t: 'Policy No' },
        { f: 'currency', t: 'Currency' },
        { f: 'adj', t: 'Adj', type: 'number' },
        { f: 'advance', t: 'Advance', type: 'number' },
        { f: 'billed', t: 'Billed', type: 'number' },
        { f: 'netBilled', t: 'Net Billed', type: 'number' },
        { f: 'tax', t: 'Tax', type: 'number' },
        { f: 'premium', t: 'Premium', type: 'number' },
        { f: 'control', t: 'Control' },
      ],
    };
    this.innerTable.columns.forEach((x) => {
      if (x.type === 'number') x.formatter = this.uS.moneyParser;
    });
    this.innerTable.data = await this.homeS
      .searchCommStatements({ statementNo: row.statementNo })
      .toPromise();
    this.innerLoading = false;
  }

  openRAChart() {
    this.uS.dialogOpener(
      RenewalAnalyticsComponent,
      {
        width: '80%',
        height: '80%',
      },
      () => {},
    );
  }
}
