<modal-header [header]="'Renewal Chart'" [dialogRef]="dialogRef"></modal-header>
<loader [loading]="loading">
  <div class="row mb-3">
    <div class="col-md-3">
      <app-input-basic [form]="form" name="policyNo" label="PolicyNo" />
    </div>
    <div class="col-md-3">
      <app-autocomplete
        [form]="form"
        name="month"
        labelField="description"
        valueField="code"
        [options]="months"
        label="Month" />
    </div>
    <div class="col-md-3">
      <app-input-basic [form]="form" name="year" label="Year" />
    </div>
    <div class="col-md-auto mt-4 pt-1">
      <app-btn [form]="form" text="Load Chart" (mclick)="raCDLoad(btn)" #btn />
    </div>
  </div>

  <ng-container *ngIf="showChart">
    <canvas
      ngChart
      [options]="raCD?.options"
      [datasets]="raCD?.data.datasets"
      [labels]="raCD?.data.labels"
      [chartType]="raCD?.type"></canvas>
  </ng-container>
</loader>
